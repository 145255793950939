import { useNotifyStore } from '@/store/notify';
import {
  mdiAccountGroupOutline,
  mdiAlertCircle,
  mdiBullhornOutline,
  mdiEarth,
  mdiPen,
  mdiRobot,
  mdiShareOutline,
} from '@mdi/js';
import { useConversationStore } from '@/store/conversation';
import { useModelsStore } from '@/store/models';
import {
  IColor,
  IConversation,
  IForm,
  IKnowledgebase,
  IModel,
} from '@/interfaces/interfaces';
import {
  IFlow,
  IFlowNodeParam,
  IFlowNodeParamData,
  IFlowNodeParamOption,
} from '@/interfaces/flows.interfaces';
import {
  COLORS,
  CONVERSATION_STATE,
  FILE_EXTENSION,
  FILE_TYPE,
  MODEL_TYPE,
  PERMISSIONS,
  UNKNOWN_SOURCE,
  CONVERSATION_SOURCE,
  LD_FLAGS,
} from '@/constants/constants';
import {
  FLOW_NODE_PARAM_ORDER,
  FLOW_NODE_TEMPLATE_GROUP,
  FLOW_USE_CASE_DATA,
} from '@/constants/flows.constants';
import { customAlphabet } from 'nanoid';
import { useUserStore } from '@/store/user';
import {
  uniqueNamesGenerator,
  adjectives,
  colors,
} from 'unique-names-generator';
import { useFlowsStore } from '@/store/flows';
import { CreateConversationDTO } from '@/interfaces/dtos';
import { ROUTE_NAMES } from '@/constants/routes';
import { ROLES } from '@/constants/roles';
import { storeToRefs } from 'pinia';
import { SIMULATION_JOB_TYPE } from '@/constants/simulations.constants';
import { ISimulation } from '@/interfaces/simulations.interface';
import { useLDFlag } from 'launchdarkly-vue-client-sdk';

const alpha = 'abcdefghijklmnopqrstuvwxyz';
const nanoid = customAlphabet(`1234567890${alpha}${alpha.toUpperCase()}`, 22);

export class HelperService {
  aggregateEntriesValues<T>(
    item: Record<string | number, T>,
    multiplier: number = 1,
  ) {
    const values = Object.entries(item)
      .map(([, val]) => val)
      .filter(Number) as number[];
    return (values.reduce((a, c) => a + c, 0) * multiplier) / values.length;
  }

  modelHasDebugger(model?: IModel | null) {
    return (
      model?.model_type === MODEL_TYPE.GENERIC_SIMPLE ||
      model?.model_type === MODEL_TYPE.GENERIC_LANGCHAIN ||
      model?.model_type === MODEL_TYPE.LANGCHAIN_FLOW
    );
  }

  modelHasSettings(model?: IModel | null) {
    const pre_prompt = model?.model_user_inputs_config.pre_prompt ?? false;
    const user_profile =
      (model?.model_user_inputs_config.user_profile?.length ?? 0) > 0;
    const prompt_messages_count =
      model?.model_user_inputs_config.prompt_messages_count ?? false;
    const prompt_header_override =
      model?.model_user_inputs_config.prompt_header_override ?? false;
    return (
      pre_prompt ||
      user_profile ||
      prompt_messages_count ||
      prompt_header_override
    );
  }

  canEditConversation(conversation: IConversation | null) {
    const { userData } = useUserStore();
    const isOwner = conversation?.user_id === userData?.uid;
    const hasAccount = !!(
      conversation?.account_id &&
      conversation.account_id == userData?.account_id
    );
    return (
      (isOwner || hasAccount) && conversation?.state === CONVERSATION_STATE.OPEN
    );
  }

  showTermsDialog() {
    const { showConfirmDialog } = useNotifyStore();
    const { agreeTerms, signOut } = useUserStore();
    showConfirmDialog({
      title: 'Terms and Privacy',
      body: `By accepting these terms on behalf of a customer of LivePerson, you represent 
      that you have the authority to accept on behalf of such customer including its affiliates 
      (the “Company”). Use of this product is subject to (i) the master services agreement or 
      similar agreement in place between LivePerson and Company and (ii) the applicable terms for 
      the LLM Features on the <a href="https://www.liveperson.com/policies/additional-product-and-third-party-application-terms/" target="_blank">Additional Terms</a> 
      and <a href="https://www.liveperson.com/policies/analytics-beta-tou/" target="_blank">Beta Terms</a> pages.`,
      icon: mdiPen,
      persistent: true,
      hideClose: true,
      primaryAgree: true,
      primaryAgreeLabel: 'I have read the Terms and Conditions',
      primaryLabel: 'Agree',
      closeOnPrimary: false,
      closeAfterPrimary: true,
      onPrimaryFn: () => agreeTerms(),
      cancelLabel: 'Disagree',
      onCancelFn: () => signOut(),
    });
  }

  findAllowedRoute(
    routes: {
      name: ROUTE_NAMES;
      allowedRoles: ROLES[];
      allowedPermission?: PERMISSIONS;
    }[],
  ) {
    const { hasRole, hasPermission } = useUserStore();
    const firstAllowedRoute = routes.find(
      (route) =>
        hasRole(route.allowedRoles) ||
        (route.allowedPermission && hasPermission(route.allowedPermission)),
    );
    return firstAllowedRoute?.name;
  }

  isModel(item: IFlow | IModel): item is IModel {
    return 'model_type' in item;
  }

  isCCModel(modelId?: string) {
    const { modelEntities } = useModelsStore();
    if (!modelId) return false;
    return modelEntities[modelId].model_type === MODEL_TYPE.CONVERSATION_CLOUD;
  }

  getFlowTypeData(item: IFlow) {
    if (item.cb_tile_metadata) {
      const { cb_bot_name, cb_tile_name } = item.cb_tile_metadata;
      const prefix = 'CB Flow';
      const name = cb_bot_name || cb_tile_name;
      return {
        icon: mdiRobot,
        label: name ? `${prefix}: ${name}` : prefix,
      };
    }
    if (item.template && item.template_group) {
      switch (item.template_group) {
        case FLOW_NODE_TEMPLATE_GROUP.OFFICIAL:
          return {
            icon: mdiEarth,
            label: 'Global',
          };
        case FLOW_NODE_TEMPLATE_GROUP.COMMUNITY:
          return {
            icon: mdiAccountGroupOutline,
            label: 'Community',
          };
      }
    }
    if (item.use_case) {
      return {
        icon: FLOW_USE_CASE_DATA[item.use_case].icon,
        label: FLOW_USE_CASE_DATA[item.use_case].title,
      };
    }

    return null;
  }

  startNewConversation(isSaved: boolean, dto: CreateConversationDTO) {
    const {
      updateConversation,
      createConversation,
      conversation,
      closeConvCloudConversation,
      getOpenConvCloudConversations,
    } = useConversationStore();
    const { showSnackbar, showConfirmDialog } = useNotifyStore();
    const { selectModel } = useModelsStore();
    const { getFlowById, selectFlow } = useFlowsStore();
    const { hasConversationCloudNode, flowConversationCloudSkillId } =
      storeToRefs(useFlowsStore());

    const body = isSaved
      ? 'This will close the conversation and start a new one.'
      : 'This will close the conversation (and potentially save it in your library) and start a new one.';

    showConfirmDialog({
      icon: mdiAlertCircle,
      iconColor: 'red',
      title: 'Are you sure?',
      body,
      primaryLabel: 'Yes, close',
      closeOnPrimary: true,
      onPrimaryFn: async () => {
        if (dto.model_id) selectModel(dto.model_id);
        if (dto.flow_id) {
          await getFlowById(dto.flow_id);
          selectFlow(dto.flow_id);
        }
        if (this.isCCModel(dto.model_id) || hasConversationCloudNode.value) {
          const ccConversations = await getOpenConvCloudConversations();
          ccConversations && ccConversations.length
            ? await closeConvCloudConversation(ccConversations[0].id)
            : () => {};
        }
        createConversation(
          {
            ...dto,
            source: hasConversationCloudNode.value
              ? CONVERSATION_SOURCE.CONVERSATIONAL_CLOUD
              : CONVERSATION_SOURCE.AI_DOJO,
            conversation_cloud_skill_id: flowConversationCloudSkillId.value,
            conversation_cloud_rest_api: hasConversationCloudNode.value,
          },
          true,
        );
      },
      secondaryLabel: 'Yes, close and save',
      closeOnSecondary: false,
      onSecondaryFn:
        isSaved || !conversation
          ? undefined
          : async () => {
              const savedConv = await updateConversation(conversation, false);
              if (savedConv) {
                showSnackbar(
                  'Previous conversation saved',
                  `ID: ${savedConv.id}`,
                );
              } else {
                showSnackbar(
                  'Something went wrong',
                  `Failed to save previous conversation`,
                );
              }
              if (dto.model_id) selectModel(dto.model_id);
              if (dto.flow_id) selectFlow(dto.flow_id);
              if (
                this.isCCModel(dto.model_id) ||
                hasConversationCloudNode.value
              ) {
                const ccConversations = await getOpenConvCloudConversations();
                ccConversations && ccConversations.length
                  ? await closeConvCloudConversation(ccConversations[0].id)
                  : () => {};
              }
              createConversation(
                {
                  ...dto,
                  source: hasConversationCloudNode.value
                    ? CONVERSATION_SOURCE.CONVERSATIONAL_CLOUD
                    : CONVERSATION_SOURCE.AI_DOJO,
                  conversation_cloud_skill_id:
                    flowConversationCloudSkillId.value,
                  conversation_cloud_rest_api: hasConversationCloudNode.value,
                },
                true,
              );
            },
    });
  }

  submitFeedback(displayName: string, onPrimary: () => Promise<any>) {
    const { showConfirmDialog } = useNotifyStore();
    showConfirmDialog({
      icon: mdiBullhornOutline,
      iconColor: 'red',
      title: `Send feedback for ${displayName ?? 'bot'}`,
      body: 'In order to submit your feedback, this conversation must be saved in your conversation library.',
      primaryLabel: 'Save and Send',
      closeOnPrimary: false,
      onPrimaryFn: onPrimary,
    });
  }

  reduceForm<T>(form: IForm[]) {
    return form.reduce((prev, item) => {
      if (item.exclude) {
        return prev;
      }
      const key = item.key as keyof T;
      (prev[key] as keyof T) = item.ref.value;
      return prev;
    }, {} as T);
  }

  reduceFormData(form: IForm[]) {
    return form.reduce((prev, item) => {
      if (item.exclude) {
        return prev;
      }
      if (item.ref.value !== null && item.ref.value !== undefined) {
        prev.append(item.key, item.ref.value);
      }
      return prev;
    }, new FormData());
  }

  updateForm<T>(form: IForm[], target: T) {
    form.forEach((item) => {
      const key = item.key as keyof T;
      if (target && Object.hasOwn(target, key)) {
        item.ref.value = target[key];
      }
    });
  }

  private async shareConversation(
    conversation: IConversation,
    doRoute: boolean,
  ) {
    const { updateConversation } = useConversationStore();
    const { showConfirmDialog, showSnackbar } = useNotifyStore();

    conversation.public = true;

    const updatedConv = await updateConversation(conversation, doRoute);
    if (!updatedConv) {
      return showSnackbar(
        'Something went wrong, please try again later',
        'Failed to share conversation',
      );
    }
    return showConfirmDialog({
      maxWidth: 400,
      icon: mdiShareOutline,
      title: 'Share conversation',
      body: `This conversation is now public. Anyone with link can view.`,
      secondaryLabel: 'Copy link',
      cancelLabel: 'Close',
      closeOnSecondary: true,
      onSecondaryFn: async () => {
        const url = `${location.protocol}//${location.hostname}/conversation-playground/${updatedConv.id}`;
        await navigator.clipboard.writeText(url);
        return showSnackbar(
          'Conversation link copied to clipboard',
          `ID: ${updatedConv.id}`,
        );
      },
    });
  }

  isConversationOriginPrivate(conversation: IConversation) {
    const { modelEntities } = useModelsStore();
    const { flowEntities } = useFlowsStore();

    if (conversation.model_id) {
      return modelEntities[conversation.model_id]?.private ?? true;
    }

    if (conversation.flow_id) {
      return !flowEntities[conversation.flow_id]?.flow.public ?? true;
    }

    return true;
  }

  async toggleConversationPrivacy(
    conversation: IConversation,
    doRoute: boolean,
  ) {
    const { updateConversation } = useConversationStore();
    const { showConfirmDialog, showSnackbar } = useNotifyStore();

    if (conversation.public) {
      conversation.public = false;
      await updateConversation(conversation, doRoute);
      return showSnackbar('Conversation is now private');
    }

    const isOriginPrivate = this.isConversationOriginPrivate(conversation);

    if (isOriginPrivate) {
      return showConfirmDialog({
        maxWidth: 400,
        icon: mdiAlertCircle,
        iconColor: 'red',
        title: 'Cannot share conversation',
        body: `Conversations can only be shared from public origins - please update bot or flow privacy and try again.`,
        primaryLabel: 'Close',
        closeOnPrimary: true,
        closeAfterPrimary: false,
        onPrimaryFn: async () => true,
      });
    }

    if (conversation.saved) {
      return this.shareConversation(conversation, doRoute);
    }
    return showConfirmDialog({
      maxWidth: 400,
      icon: mdiShareOutline,
      title: 'Share conversation',
      body: `In order to share, this conversation must be saved in your conversation library.`,
      primaryLabel: 'Save and share',
      closeOnPrimary: false,
      closeAfterPrimary: false,
      onPrimaryFn: () => this.shareConversation(conversation, doRoute),
    });
  }

  canSetSimulationPrivacy(simulation: ISimulation) {
    const { modelEntities } = useModelsStore();
    const { flowEntities } = useFlowsStore();
    const { showConfirmDialog } = useNotifyStore();

    if (!simulation.public) {
      return true;
    }
    const modelIds: Set<string> = new Set();
    const flowIds: Set<string> = new Set();
    simulation.jobs.forEach((job) => {
      switch (job.type) {
        case SIMULATION_JOB_TYPE.MODEL:
          if (job.model_id) modelIds.add(job.model_id);
          if (job.model_sim_id) modelIds.add(job.model_sim_id);
          break;
        case SIMULATION_JOB_TYPE.FLOW:
          if (job.model_id) flowIds.add(job.model_id);
          if (job.model_sim_id) flowIds.add(job.model_sim_id);
          break;
      }
    });
    const hasPrivateModels = Array.from(modelIds).some(
      (id) => modelEntities[id] && modelEntities[id].private,
    );
    const hasPrivateFlows = Array.from(flowIds).some(
      (id) => flowEntities[id] && !flowEntities[id].flow.public,
    );

    if (hasPrivateModels || hasPrivateFlows) {
      showConfirmDialog({
        maxWidth: 400,
        icon: mdiAlertCircle,
        iconColor: 'red',
        title: 'Cannot share simulation',
        body: `Simulations can only be public if each selected bot or flow is public - please update bot or flow privacy and try again.`,
        primaryLabel: 'Close',
        closeOnPrimary: true,
        closeAfterPrimary: false,
        onPrimaryFn: async () => false,
      });
      return false;
    }

    return true;
  }

  getColors(ids: string[]) {
    let index = 0;
    const colors = new Map<string, IColor>();
    ids.forEach((id, idx) => {
      if (index > idx) index = 0;
      colors.set(id, COLORS[index]);
      index++;
    });
    return colors;
  }

  getFileTypeFromExtension(extension: FILE_EXTENSION) {
    switch (extension) {
      case FILE_EXTENSION.PDF:
        return FILE_TYPE.PDF;
      case FILE_EXTENSION.JSON:
        return FILE_TYPE.JSON;
      case FILE_EXTENSION.CSV:
        return FILE_TYPE.CSV;
    }
  }

  getFileTypeFromFilename(filename: string) {
    const extensionParts = filename.split('.');
    const extension = extensionParts[extensionParts.length - 1] ?? null;
    switch (extension) {
      case 'pdf':
        return 'application/pdf';
      case 'json':
        return 'application/json';
      case 'csv':
        return 'text/csv';
      default:
        return null;
    }
  }

  convertPathToFile(path: string) {
    const filenameParts = path.split('/');
    return filenameParts[filenameParts.length - 1] ?? path;
  }

  uuid(size?: number) {
    return nanoid(size);
  }

  canEditKnowledge(knowledgebase: IKnowledgebase) {
    const { userData, hasAccountId, hasRole } = useUserStore();

    if (hasRole([ROLES.ADMIN, ROLES.MANAGER])) {
      return true;
    }

    const isOwner = knowledgebase.created_by == userData?.uid;
    const hasAccount = knowledgebase.account_id
      ? hasAccountId(knowledgebase.account_id)
      : false;
    return isOwner || hasAccount;
  }

  canManageKnowledge(knowledgebase: IKnowledgebase) {
    const { hasRole } = useUserStore();

    if (hasRole([ROLES.ADMIN, ROLES.MANAGER])) {
      return true;
    }

    if (
      hasRole([ROLES.MODEL_MANAGER, ROLES.EXTERNAL_MODEL_MANAGER]) &&
      this.canEditKnowledge(knowledgebase)
    ) {
      return true;
    }

    return false;
  }

  canEditSimuation(simulation: ISimulation) {
    const { userData, hasAccountId, hasRole } = useUserStore();

    if (hasRole([ROLES.ADMIN, ROLES.MANAGER])) {
      return true;
    }

    const isOwner = simulation.created_by == userData?.uid;
    const hasAccount = simulation.account_id
      ? hasAccountId(simulation.account_id)
      : false;
    return isOwner || hasAccount;
  }

  canEditFlow(flow: IFlow) {
    const { userData, hasAccountId, hasRole } = useUserStore();

    if (hasRole([ROLES.ADMIN, ROLES.MANAGER])) {
      return true;
    }

    const isOwner = flow.created_by == userData?.uid;
    const hasAccount = flow.account_id ? hasAccountId(flow.account_id) : false;
    return isOwner || hasAccount;
  }

  scrollToLatest(refs: any[]) {
    if (refs.length) {
      const newMessage = refs[refs.length - 1];
      const element = newMessage.$el as HTMLElement;
      element.parentElement?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }

  canDisplayNodeParam(
    param: IFlowNodeParam,
    paramsData: Record<string, IFlowNodeParamData>,
  ) {
    if (!param.display_conditions) {
      return true;
    }
    return Object.entries(param.display_conditions).every(([key, val], idx) => {
      if (paramsData[key]) {
        const targetParam = paramsData[key];
        if (Array.isArray(val)) {
          return val.includes(targetParam.value);
        }
        return targetParam.value === val;
      }
      return true;
    });
  }

  nodeParamSorter(
    [aKey, aParam]: [string, IFlowNodeParam],
    [bKey, bParam]: [string, IFlowNodeParam],
  ) {
    const aRequired = aParam.required ? 1 : 0;
    const bRequired = bParam.required ? 1 : 0;
    const aOrder = FLOW_NODE_PARAM_ORDER[aParam.param_type] ?? -1;
    const bOrder = FLOW_NODE_PARAM_ORDER[bParam.param_type] ?? -1;
    return bOrder + bRequired - (aOrder + aRequired);
  }

  generateUniqueName() {
    return uniqueNamesGenerator({
      dictionaries: [adjectives, colors],
      separator: ' ',
      length: 2,
    });
  }

  generateParamOptions(
    paramOptions: IFlowNodeParamOption[],
    paramsData: Record<string, IFlowNodeParamData>,
  ) {
    return paramOptions.filter((option) => {
      if (!option.display_conditions) return option;

      const conditions = Object.entries(option.display_conditions);

      return conditions.every(([paramKey, condition]) => {
        const paramValue = paramsData[paramKey]?.value;
        if (Array.isArray(condition)) {
          return condition.includes(paramValue);
        }
        return paramValue === condition;
      });
    });
  }

  getConversationTitle(item?: IConversation | null) {
    const { modelEntities } = useModelsStore();
    const { flowEntities } = useFlowsStore();

    if (item?.model_id) {
      return (
        modelEntities[item.model_id]?.display_name ??
        (item.custom_name || UNKNOWN_SOURCE.MODEL_NAME)
      );
    }

    if (item?.flow_id) {
      return (
        flowEntities[item.flow_id]?.flow.display_name ??
        (item.custom_name || UNKNOWN_SOURCE.MODEL_NAME)
      );
    }

    return item?.custom_name ?? UNKNOWN_SOURCE.MODEL_NAME;
  }

  getSimulationName(item: ISimulation) {
    const job = item.jobs[0];
    const model_id = job?.model_id ?? '';
    const { modelEntities } = storeToRefs(useModelsStore());
    const { flowEntities } = storeToRefs(useFlowsStore());
    const model =
      job?.type === SIMULATION_JOB_TYPE.MODEL
        ? modelEntities.value[model_id] ?? null
        : flowEntities.value[model_id]?.flow ?? null;
    const length = item.jobs.length - 1;
    const plural = length > 1 ? 's' : '';
    const suffix = length > 0 ? ` (+${length} bot${plural})` : '';
    return model ? `${model.display_name}${suffix}` : item.type.toLowerCase();
  }

  isAuthorized(roles: ROLES[] = [], permission?: PERMISSIONS): boolean {
    const userStore = useUserStore();
    return (
      (roles.length ? userStore.hasRole(roles) : true) &&
      (permission ? userStore.hasPermission(permission) : true)
    );
  }

  isEnabled(ldFlag: LD_FLAGS): boolean {
    return useLDFlag<boolean>(ldFlag).value;
  }
}

export default new HelperService();
